<template>
	<div class="partner">
		<div class="header">
			<img src="@/assets/images/partner_header.png" alt="">
			<div class="inner animated fadeIn">
				<p>{{$t('lang.home.nav6')}}</p>
				<p>{{$t('lang.partner.header1')}}</p>
			</div>
		</div>
		<div class="person">
			<div class="item animated fadeInUp" v-for="item of personList" :key="item.name">
				<div class="avatar" @click="open(item.url)" :style="{background:`url(${item.img}) no-repeat center`,backgroundSize:'100%'}"></div>
				<div class="message">
					<p class="name">{{item.name}}</p>
					<p class="des">{{item.des}}</p>
					<p v-for="(e,i) of item.list" :key="i">{{e}}</p>
				</div>
				<a target="_blank" v-if="item.url" :href="item.url"><img src="@/assets/images/go_website.png" class="goto" alt=""></a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: "partner",
  data() {
    return {
      personList: [
        {
          img: require("@/assets/images/partner_1.png"),
          name: "Garry Summers",
          des: "Sound Supervisor",
          list: [
            "2018年《大轰炸》",
            "2018年《守望先锋：星光闪耀》",
            "2018年《魔兽世界：争霸艾泽拉斯》 ",
            "2018年《魔兽世界：老兵》"
          ],
          url: ""
        },
        {
          img: require("@/assets/images/partner_2.png"),
          name: "LIVE TONE",
          des: "Digital Sound Production",
          list: [
            "2008. 第16届利川春史电影节 技术奖《追击者》",
            "2008. 第七届⼤韩⺠国电影⼤奖 ⾳响奖《好家伙、坏家伙、怪家伙》",
            "2011. 第58届MPSE Golden Reel Award(USA) Best Sound 候补作品《⺟亲》",
            "2011. 第48届⼤钟奖电影节 ⾳响技术奖《最终兵器:⼸》"
          ],
          url: "http://www.livetone.co.kr"
        },
        {
          img: require("@/assets/images/partner_3.png"),
          name: "Vision Holdings",
          des: "VFX & New Media",
          list: [
            "2005年纽约⼴告节⾦奖",
            "2005年戛纳⼴告节银狮奖",
            "2011年伦敦⼴告节⾦奖",
            "2016年进军中国上海⼴告市场"
          ],
          url: "http://www.visionholdingscorp.com"
        }
      ]
    };
  },
  methods: {
    open(url) {
      if (url) {
        window.open(url);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.partner {
  .person {
    width: 1280px;
    margin: 120px auto;
    .item {
      margin-top: 50px;
      width: 100%;
      overflow: hidden;
      position: relative;
    }
    .avatar {
      width: 281px;
      height: 364px;
      margin-right: 54px;
      float: left;
      cursor: pointer;
    }
    .message {
      float: left;
      p {
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        opacity: 0.8;
        font-family: "OpenSansLight";
      }
      .name {
        font-size: 32px;
        margin-bottom: 15px;
        opacity: 1;
        font-family: "OpenSansRegular";
      }
      .des {
        font-size: 22px;
        margin-bottom: 30px;
        opacity: 1;
        font-family: "OpenSansLight";
      }
    }
    .goto {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 134px;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>

